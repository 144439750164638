import * as Yup from 'yup';

export const simpleStringValidator = Yup.string();

export const simpleStringFirstNameValidator = Yup.string().required('First Name should no be empty');

export const simpleStringMessageValidator = Yup.string().required('Message should no be empty');

export const simpleStringReviewValidator = Yup.string().required('Review should no be empty');

export const emailValidator = Yup.string().email().required('Email should not be empty');

export const dateValidator = Yup.number().nullable();

export const simpleNumberValidatorNoRequired = Yup.number();

export const simpleNumberZeroValidator = Yup.number().required().when('reviewType', {
	is: 0,
	then: Yup.number().positive('Book should no be empty').required('Book should no be empty'),
});
