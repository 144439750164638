import React from 'react';

import Line1 from '@app/components/UI/Lines/Line1';

interface Props {
	text: React.ReactNode;
	line?: React.ReactNode;
}

const SelectedText: React.FC<Props> = (props) => {
	const { text, line = <Line1 /> } = props;
	return <span className="selected-text">
		<span>{text}</span>
		{line}
	</span>;
};

export default SelectedText;
