import React from 'react';

interface PrevArrowProps {
	onClick: () => void;
	className?: string;
}

const PrevArrow: React.FC<PrevArrowProps> = ({ onClick, className }) => {
	return (
		<svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" width="49" height="21" viewBox="0 0 49 21" fill="none">
			<path
				d="M47.3427 9.82578C33.9784 9.39122 20.674 9.57486 7.36967 9.75852C11.5776 7.89791 15.9562 6.18062 20.3049
				4.15421C22.2532 3.23516 21.1601 0.0457741 19.0412 0.821521C13.1661 3.26956 7.60239 5.69509 1.72737 8.14313C0.823519
				8.51975 0.431572 9.32628 0.662181 10.0878C0.0995987 10.751 -0.0917342 12.01 0.902079 12.5607C4.59604 15.0951 8.11937
				17.4862 11.6727 20.1865C13.5197 21.4537 15.4887 19.1324 13.923 17.5336C12.046 15.9573 10.3397 14.5242 8.60339
				12.7821C21.4258 12.4777 34.5895 12.4598 47.352 11.5371C48.7677 11.5904 48.4471 9.90158 47.3427 9.82578Z"
				fill="#473373"
			/>
		</svg>
	);
};

export default PrevArrow;
