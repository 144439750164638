import React from 'react';

const Footprint: React.FC<{ color?: string }> = ({ color = 'currentColor' }) => {
	return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<g clipPath="url(#clip0_457_103)">
			<path
				d="M14.42 13.6551C14.1383 12.7073 13.504 11.9036 12.6477 11.4093C11.7914 10.9149 10.7782 10.7674 9.81658
				10.9973L5.52436 12.0201C3.09796 12.5477 2.64966 15.9754 4.86028 17.1078C6.04341 17.7492 7.3342 17.6988
				8.57993 18.4483C9.89737 19.1786 10.5629 20.2638 11.6745 21.042C13.758 22.3093 16.4275 20.2056 15.6776
				17.882L14.42 13.6551ZM21.4277 12.5557C20.4652 11.7158 18.9382 12.4445 18.1217 13.3598C15.615 16.2053
				18.3808 18.6205 20.9141 15.9192C21.9852 14.7531 22.2108 13.273 21.4277 12.5557ZM15.823 11.6386C16.9028
				12.262 18.4896 11.5414 19.3592 10.0353C21.3446 6.34702 17.6448 4.21093 15.4462 7.77616C14.5767 9.28223
				14.746 11.0168 15.823 11.6386ZM8.22145 7.64387C8.6088 6.48078 8.47346 4.79238 7.2649 4.37879C4.95935
				3.72588 3.33614 8.50611 5.29599 9.65279C6.43125 10.259 7.69207 9.26298 8.22145 7.64387ZM10.8615
				8.77405C11.9385 9.39584 13.5253 8.6752 14.3948 7.16913C16.3831 3.48249 12.6833 1.34639 10.4819
				4.90999C9.61232 6.41606 9.78164 8.15061 10.8615 8.77405Z"
				fill={color}
			/>
		</g>
		<defs>
			<clipPath id="clip0_457_103">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>;
};

export default Footprint;
