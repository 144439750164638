import * as React from 'react';

import Modal, { ModalFuncProps } from 'antd/lib/modal';
import { LegacyButtonType } from 'antd/lib/button/button';

export function actionConfirmation(
	callback: () => void,
	title?: string,
	okText?: string,
	cancelText?: string,
	okType?: LegacyButtonType,
	getContainer?: (instance?: React.ReactInstance) => HTMLElement,
	options?: ModalFuncProps,
) {
	Modal.confirm({
		...options,
		title: title || 'Are you sure you want to do this?',
		content: '',
		okText: okText || 'Yes',
		okType: okType || 'primary',
		cancelText: cancelText || 'No',
		onOk() {
			callback();
		},
		getContainer,
	});
}

export function deleteConfirmation(
	callback: () => void,
	title?: string,
	getContainer?: (instance?: React.ReactInstance) => HTMLElement,
	options?: ModalFuncProps,
) {
	Modal.confirm({
		...options,
		title: title || 'This item will be permanently deleted and cannot be recovered. Are you sure?',
		content: '',
		okText: 'Yes',
		okType: 'danger',
		cancelText: 'Cancel',
		onOk() {
			callback();
		},
		getContainer,
		className: options?.className || 'editable-table-delete-modal',
	});
}
