import React from 'react';

const Line2: React.FC = () => {
	return <svg xmlns="http://www.w3.org/2000/svg" width="316" height="88" viewBox="0 0 316 88" fill="none">
		<path
			d="M255.677 2.0869C246.53 2.0869 237.373 1.99877 228.228 2.16261C204.91 2.58036 181.607 5.34822 158.667
			8.09282C132.006 11.2827 105.361 15.1238 79.3548 20.357C58.0688 24.6403 33.2148 29.131 16.4394 39.8636C2.29924
			48.9101 -5.24015 65.1079 11.8164 74.7131C28.5924 84.1602 55.7319 84.821 76.0321 85.665C121.167 87.5415 166.104
			82.5601 209.953 75.4701C234.757 71.4597 259.97 66.881 283.415 59.8244C293.446 56.8051 304.826 52.9564 310.719
			46.1471C320.235 35.1516 307.337 23.1258 293.925 17.3793C277.846 10.4902 257.683 10.803 239.497 12.1809C228.69
			12.9997 217.319 15.0332 207.569 18.4391"
			stroke="#FFDD60"
			strokeWidth="3"
			strokeLinecap="round"
		/>
	</svg>;
};

export default Line2;
