import React from 'react';

const Line1: React.FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="143" height="54" viewBox="0 0 143 54" fill="none">
			<path
				d="M108.825 13.7361C100.166 11.7147 91.4848 11.069 82.5112 11.1237C71.7955 11.1891 61.3295 12.4346 50.8594
				14.3996C40.0554 16.4272 28.7889 18.5372 18.6406 22.4854C10.5734 25.624 -0.906062 33.1271 2.67297
				42.3891C4.28065 46.5494 10.0795 48.5558 14.3416 49.8737C20.3392 51.7281 26.6727 52.4458 33.0021
				52.7556C45.1422 53.3497 57.2885 52.8665 69.3072 51.242C87.183 48.8259 105.318 44.3347 121.863
				37.8693C127.888 35.5151 138.121 31.6518 140.5 25.5124C143.477 17.8331 132.459 9.71149 125.879 6.54175C118.584
				3.02805 111.24 1.63032 102.99 1.25485C94.3638 0.862221 84.9543 0.687662 76.5116 2.54029"
				stroke="#FFDD60"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Line1;
