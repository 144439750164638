import * as React from 'react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';

import 'react-datepicker/dist/react-datepicker.css';

import '@app/scss/components/datePicker.scss';

interface DatePickerAltProps {
	value: number | null;
	onChange: (date: number | null) => void;
	placeholder?: string;
	utc?: boolean;
	datePickerProps?: any;
	yearsSelectOptions?: Array<{ value, label }>;
	useDefaultFormat?: boolean;
}

const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

const year = new Date().getFullYear();

const transformToUtc = (date: number): number => {
	const d = new Date(date);
	return +new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0, 0));
};

const years = Array.from({ length: 150 }).map((_, i) => ({ value: year - 100 + i, label: year - 100 + i }));

const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const monthOption = months.map((moth, i) => ({
	value: i,
	label: moth,
}));

const getDateFormat = (date: number | null) => {
	const selectedDate = new Date((date || undefined) as any);
	return `${months[selectedDate.getMonth()]} ${selectedDate.getFullYear()}`;
};

const defaultRenderCustomHeader = (yearsSelectOptions: Array<{value, label}> = years) => ({
	date,
	changeYear,
	changeMonth,
	decreaseMonth,
	increaseMonth,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled,
}) => {
	const currentDate = new Date(date || undefined);
	const currentYear = +currentDate.getFullYear();
	const currentMonth = currentDate.getMonth();

	return <div className="react-datepicker__header react-datepicker__custom-header">
		<div className="react-datepicker__current-month">
			{getDateFormat(date)}
		</div>
		<button
			onClick={decreaseMonth}
			disabled={prevMonthButtonDisabled}
			type="button"
			className="react-datepicker__navigation react-datepicker__navigation--previous"
			aria-label="Previous Month"
		>
			Previous Month
		</button>
		<button
			onClick={increaseMonth}
			disabled={nextMonthButtonDisabled}
			type="button"
			className="react-datepicker__navigation react-datepicker__navigation--next react-datepicker__navigation--next--with-today-button"
			aria-label="Next Month"
		>
			Next Month
		</button>

		<Select<any>
			placeholder="Year"
			className="react-datepicker__year-select"
			defaultValue={{ label: currentYear, value: currentYear }}
			onChange={(option) => changeYear(option.value)}
			options={yearsSelectOptions}
			components={{
				IndicatorSeparator: () => null,
			}}
		/>
		<Select<any>
			placeholder="Month"
			className="react-datepicker__month-select"
			defaultValue={{ label: months[currentMonth], value: currentMonth }}
			components={{
				IndicatorSeparator: () => null,
			}}
			onChange={(option) => {
				const index = months.indexOf(option.label as any);
				if (index > -1) {
					changeMonth(index);
				}
			}}
			options={monthOption}
		/>
	</div>;
};

export const DatePickerReact: React.FC<DatePickerAltProps> = ({
	value,
	onChange,
	placeholder,
	datePickerProps = {},
	yearsSelectOptions,
}) => {
	const defaultValue = value;

	const innerOnChange = (date) => {
		onChange(date === null ? null : transformToUtc(+date));
	};

	const {
		showMonthDropdown, showYearDropdown, dropdownMode = 'select', maxDate,
	} = datePickerProps;

	const renderCustomHeader = React.useMemo(() => {
		return dropdownMode === 'select' ? (props) => defaultRenderCustomHeader(yearsSelectOptions)(props) : undefined;
	}, [dropdownMode, `${yearsSelectOptions}`]);

	return (
		<div className="date-picker-alt">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				<path
					d="M15.2008 4V7.2M8.80078 4V7.2M4.80078 10.4H19.2008M6.40078 5.6H17.6008C18.4844 5.6 19.2008 6.31634
					19.2008 7.2V18.4C19.2008 19.2837 18.4844 20 17.6008 20H6.40078C5.51713 20 4.80078 19.2837 4.80078
					18.4V7.2C4.80078 6.31634 5.51713 5.6 6.40078 5.6Z"
					stroke="#5D4493"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
			<DatePicker
				selected={defaultValue || undefined}
				onChange={innerOnChange}
				value={defaultValue}
				todayButton="Today"
				isClearable
				placeholderText={placeholder || 'Select date'}
				maxDate={maxDate}
				customInput={<MaskedInput
					mask={dateMask}
					className="ant-input form-control"
					guide
					id="date-picker"
				/>}
				{
					...{
						renderCustomHeader,
						...datePickerProps,
						showMonthDropdown,
						showYearDropdown,
					}
				}
			/>
		</div>
	);
};
