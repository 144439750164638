import React from 'react';

interface NextArrowProps {
	onClick: () => void;
	className?: string;
}

const NextArrow: React.FC<NextArrowProps> = ({ onClick, className }) => {
	return (
		<svg onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" width="49" height="21" viewBox="0 0 49 21" fill="none">
			<path
				d="M1.30577 9.37266C14.6701 8.93809 27.9744 9.12174 41.2788 9.30539C37.0708 7.44479 32.6922 5.72749 28.3436
				3.70108C26.3952 2.78204 27.4883 -0.407351 29.6073 0.368396C35.4823 2.81644 41.046 5.24197 46.9211 7.69001C47.8249
				8.06663 48.2169 8.87316 47.9863 9.63468C48.5488 10.2979 48.7402 11.5569 47.7464 12.1076C44.0524 14.642 40.5291 17.0331
				36.9758 19.7334C35.1288 21.0006 33.1597 18.6793 34.7254 17.0805C36.6024 15.5041 38.3087 14.0711 40.045 12.329C27.2226
				12.0245 14.0589 12.0067 1.29648 11.084C-0.119274 11.1373 0.201303 9.44845 1.30577 9.37266Z"
				fill="#473373"
			/>
		</svg>
	);
};

export default NextArrow;
