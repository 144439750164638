import React from 'react';

import { Loading } from '@common/react/components/UI/Loading/Loading';

import Footprint from '@app/components/UI/Footprint/Footprint';

interface SubmitButtonProps {
	loading: boolean;
	error: string;
	message: string;
	formikBag: any;
	textButton: string;
	textDone: string;
	textError?: string;
	disabled?: boolean;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
	loading,
	error,
	message,
	formikBag,
	textButton,
	textDone,
	textError = 'Service unavailable. Please try again later.',
	disabled = false,
}) => {
	return loading ? (
		<Loading />
	) : !error ? (
		message ? (
			<div className="h3 message-result-form text-center">{textDone}</div>
		) : (
			<div className="text-center">
				<button
					onSubmit={formikBag.submitForm}
					type="submit"
					className={disabled ? 'btn btn-primary btn-disabled' : 'btn btn-primary'}
					disabled={disabled}
				>
					<Footprint />
					{textButton}
				</button>
			</div>
		)
	) : (
		<div className="h3 error-message message-result-form text-center">
			{textError}
		</div>
	);
};

export default SubmitButton;
