import React from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router-dom';

import ServerPageProvider from '@common/react/components/Core/ServerPageProvider/ServerPageProvider';

import { MetaTags } from '@app/objects/Education';

interface HelmetTagsProps {
	lang: MetaTags;
	title?: string | undefined;
}

export const HelmetTags: React.FC<HelmetTagsProps> = ({ lang, title }) => {
	return (
		<Helmet>
			<title>
				{lang.title || title || 'Dr. Mary Gardner'}
				{' '}
				| Dr. Mary Gardner
			</title>
			<meta name="description" property="og:description" content={lang.description || undefined} />
			<meta name="description" property="twitter:description" content={lang.description || undefined} />
			<meta name="keywords" content={lang.keywords || undefined} />
		</Helmet>
	);
};

interface HelmetProviderProps {
	title?: string;
	ttl?: number;
	onChangePage?: (e: any) => void;
}

const HelmetProvider: React.FC<HelmetProviderProps> = ({
	title,
	onChangePage,
	ttl,
	children,
}) => {
	const matches = useRouteMatch();

	return (
		<ServerPageProvider
			path={matches.path}
			ttl={ttl}
			inner={(page) => {
				onChangePage && onChangePage(page);

				return (
					<>
						<HelmetTags lang={page.langs[0]} title={title} />
						{children}
					</>
				);
			}}
		/>
	);
};

export default HelmetProvider;
