import React from 'react';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import useRequest from '@common/react/hooks/useRequest';
import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import { Loading } from '@common/react/components/UI/Loading/Loading';

import Footprint from '@app/components/UI/Footprint/Footprint';
import { emailValidator } from '@app/utils/validationHelpersForm';
import '@app/scss/components/subscribe.scss';

enum SubscriptionType {
	NewPosts,
	FreeGoodies
}

interface Subscription {
	email: string;
	subscriptionType: SubscriptionType;
}

const initialValues: Subscription = {
	email: '',
	subscriptionType: SubscriptionType.NewPosts,
};

const validationSchema = Yup.object().shape({
	email: emailValidator,
});

interface SubscribeProps {
	type: SubscriptionType;
	buttonText: string;
}

const Subscribe: React.FC<SubscribeProps> = ({ type, buttonText }) => {
	initialValues.subscriptionType = type;

	const [message, setMessage] = React.useState('');
	const [error, setError] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const request = useRequest();

	const handleSubmit = (values, formikHelpers) => {
		setError('');
		setMessage('');
		setLoading(true);

		request('subscribe', values)
			.then((res) => {
				setTimeout(() => {
					setMessage('Successfully send.');
					formikHelpers.resetForm({ values: initialValues });
				}, 0);
				setTimeout(() => setMessage(''), 10000);
			})
			.catch((e) => {
				setError('Something went wrong');
				setTimeout(() => setLoading(false), 300);
				setTimeout(() => setError(''), 10000);
			})
			.finally(() => {
				setTimeout(() => setLoading(false), 300);
			});
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			{
				(formikBag) => {
					return <Form>
						<FormikField
							title=""
							containerClassName="form-group"
							fieldName="email"
							inputProps={{
								placeholder: 'Email',
							}}
						/>
						{loading
							? <Loading />
							: (!error
								? (message
									? <>
										<div className="h3 message-result-form text-center">
											Thank you for subscribing to Dr. Mary Gardner
										</div>
									</>
									: (
										<div>
											<button
												onSubmit={formikBag.submitForm}
												type="submit"
												className="btn btn-primary"
											>
												<Footprint />
												{buttonText}
											</button>
										</div>
									))
								: <div className="h3 error-message message-result-form text-center">
									Something went wrong
								</div>
							)
						}
					</Form>;
				}
			}
		</Formik>
	);
};

export default Subscribe;
