import React from 'react';

import '@app/scss/components/tabs.scss';
import SelectedText from '@app/components/UI/SelectedText';

export interface Tab {
	key: string;
	label: string | React.ReactNode;
	children: React.ReactNode;
}

interface Props {
	items: Array<Tab>;
	defaultKey?: string;
	line?: React.ReactNode;
	onTabChange?: (key) => void;
	onItemChange?: (item) => void;
}

const Tabs: React.FC<Props> = ({
	items,
	defaultKey,
	line,
	onTabChange,
	onItemChange,
}) => {
	React.useEffect(() => {
		if (defaultKey) {
			setSelectedTab(defaultKey);
		}
	}, [defaultKey]);

	const [selectedTab, setSelectedTab] = React.useState(defaultKey || items[0]?.key);
	const [open, setOpen] = React.useState(false);

	const handleTabChange = (item) => {
		setSelectedTab(item.key);
		onTabChange && onTabChange(item.key);
		onItemChange && onItemChange(item);
	};

	return <div className="tabs">
		<div className="tabs-menu is-relative">
			<ul>
				{items.map((item) => (
					<li
						className={`h3 tabs-menu__item ${item.key === selectedTab ? 'tabs-menu__item__selected selected-text' : ''}`}
						key={item.key}
						onClick={() => {
							handleTabChange(item);
							setOpen((prev) => !prev);
						}}
					>
						{item.key === selectedTab ? <svg
							className={`arrow ${open ? 'arrow__open' : ''}`}
							xmlns="http://www.w3.org/2000/svg"
							width="14"
							height="9"
							viewBox="0 0 14 9"
							fill="none"
						>
							<path
								d="M6.69706 6.53559C6.43477 6.49219 2.20645 2.63329 1.28028 1.54075C0.928672 1.14319 0.299454 1.68632 0.651062
								2.08388C2.38903 4.28323 4.25855 6.41971 6.06229 8.58763C6.22237 8.75352 6.53117 8.80793 6.69706 8.64785C7.04601 8.27907
								7.62053 7.78708 7.96948 7.4183C9.99158 5.56306 11.628 3.66513 13.3128 1.52671C13.6131 1.14076 13.0042 0.542986 12.6553
								0.911762C10.7038 3.04982 6.95936 6.57899 6.69706 6.53559Z"
								fill="#352557"
							/>
						</svg> : null}
						<SelectedText
							line={line}
							text={item.label}
						/>
					</li>
				))}
			</ul>
			<ul key="tabs-menu__dropdown" className={`tabs-menu__dropdown ${open ? 'tabs-menu__dropdown__open' : ''}`}>
				{items.filter((item) => item.key !== selectedTab).map((item) => (
					<li
						className="tabs-menu__item h3"
						key={item.key}
						onClick={() => {
							handleTabChange(item);
							setOpen(false);
						}}
					>
						{item.label}
					</li>
				))}
			</ul>
		</div>
		{(items.find((item) => item.key === selectedTab) || items[0]).children}
	</div>;
};

export default Tabs;
