import React from 'react';

interface Props {
	image: React.ReactNode;
	text: React.ReactNode;
	className?: string;
	imageLeftSide?: boolean;
	id?: string;
}

const Card: React.FC<Props> = (props) => {
	const {
		className = '', image, imageLeftSide = true, text, id,
	} = props;
	const imageNode = (
		<div className="card-image">
			{image}
		</div>
	);
	return <div id={id} className={`card ${className}`}>
		{imageLeftSide ? imageNode : null}
		<div className="card-text">
			{text}
		</div>
		{imageLeftSide ? null : imageNode}
	</div>;
};

export default Card;
