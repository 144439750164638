import React from 'react';

import Footprint from '@app/components/UI/Footprint/Footprint';

import '@app/scss/components/LastTab.scss';

const recommended = {
	items: [
		'/images/it-s-never-long-enough.jpg',
		'/images/care-journal.jpg',
		'/images/cat-forever-friend-cover-july-9-1_2.png',
		'/images/Forever-Friends---Cover-Only 1.png',
	],
	link: 'https://www.amazon.com/shop/drmarygardner/list/33DL580L38SNW',
};

const LastTab = () => {
	return (
		<div className="last-tab">
			<div className="text-center">
				<div className="h2">Books for Vets and Pet Owners</div>
				<div className="h4">
					Explore the carefully curated selection of books on Amazon by visiting Dr. Mary's Amazon Page. Discover a wealth of valuable
					resources on topics such as coping with pet loss, providing loving care for older pets, and much more.
				</div>
				<div className="recommendation-item">
					{recommended.items.map((image, i) => (
						<img key={i} src={image} alt="Book" />
					))}
				</div>
				<a href={recommended.link} className="btn btn-primary" target="_blank" rel="nofollow noreferrer">
					<Footprint />
					Click here to browse
				</a>
			</div>
		</div>
	);
};

export default LastTab;
