import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { ItemProviderContext, useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import { handleUrl } from '@common/react/utils/FIltersParamsFromUrl/FiltersParamsFromUrl';

export interface ItemEditorWrapperProps<T extends WithDeleted> {
	/**
	 * function providing ItemProvider context and two callbacks
	 * @param context - ItemProviderContext
	 * @param handlerBack - callback for 'Back' button
	 * @param afterSubmit - after submit callBack
	 */
	render: (context: ItemProviderContext<T>, handlerBack: () => void, afterSubmit: (T) => void) => React.ReactNode;
	/**
	 * default back path for handleBack
	 */
	backPath: string;
	/**
	 * how handle page in url. By default false
	 * `pathname/${page}` or `${pathname}?page=${page}`
	 */
	pageInSearch?: boolean;
	/**
	 * custom afterSubmit
	 * @param item - item saved item
	 * @param setItem - setItem from ItemProvider context
	 * @param history - history from react-router-dom
	 * @param pageInSearch - same as ItemEditorWrapper pageInSearch props
	 */
	afterSubmit?: (item: T, setItem: React.Dispatch<React.SetStateAction<T>>, history, pageInSearch?: boolean) => void;
	/**
	 * custom handlerBack
	 * @param history - history from react-router-dom
	 * @param backPath - same as ItemEditorWrapper backPath props
	 */
	handlerBack?: (history, backPath: string) => void;
}

const defaultHandleBack = (history, backPath: string) => {
	const { location } = history;
	if (location.state?.prevPath) {
		history.push(location.state.prevPath);
	} else {
		history.push(backPath);
	}
};

const defaultAfterSubmit = (item, setItem, history, pageInSearch) => {
	setItem({ ...item });
	const { location } = history;
	if (pageInSearch) {
		const filters = {
			page: pageInSearch ? item.id : undefined,
		};

		handleUrl(filters, location, history, item.id, '', pageInSearch);
	} else {
		history.replace({
			...location,
			pathname: location.pathname.replace('/-1', `/${item.id}`),
		});
	}
};

/**
 * ItemEditorWrapper component.
 *
 * usage examples:
 *  - <ItemEditorWrapper backPath="/item-list" render={(context, handlerBack, afterSubmit) => React.ReactNode} />
 *
 * @typeParam T - T Any {WithDeleted}
 * @param props - ItemEditorWrapperProps
 * @type {React.FC<ItemEditorWrapperProps>}
 * @returns React.ReactElement
 */
export const ItemEditorWrapper: <T extends WithDeleted>(p: ItemEditorWrapperProps<T>) => React.ReactElement<T> = <T extends WithDeleted, >({
	render,
	backPath,
	pageInSearch = false,
	handlerBack: handleBackProps = defaultHandleBack,
	afterSubmit: afterSubmitProps = defaultAfterSubmit,
}) => {
	const context = useItemProviderContext<T>();

	if (!context.state) throw 'Need ItemProvider context!';

	const {
		actions: { setItem },
		state: { item, pageLoading },
	} = context;

	const history = useHistory<{prevPath?: string}>();

	const handlerBack = React.useMemo(() => {
		return () => {
			handleBackProps(history, backPath);
		};
	}, [history, backPath]);

	const afterSubmit = React.useMemo(() => {
		return (item: T) => {
			afterSubmitProps(item, setItem, history, pageInSearch);
		};
	}, [setItem, history, pageInSearch]);

	return !item || pageLoading
		? <Loader />
		: <div className="container">{render(context, handlerBack, afterSubmit)}</div>;
};
