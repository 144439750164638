import React from 'react';

interface StarProps {
	color: string;
	key?: number | string;
}

const Star: React.FC<StarProps> = ({ color, key }) => {
	return (
		<svg key={key} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
			<path
				d="M10.5 15.1112L15.9075 18.375L14.4725 12.2238L19.25 8.085L12.9588 7.5425L10.5 1.75L8.04125 7.5425L1.75 8.085L6.51875 12.2238L5.0925 18.375L10.5 15.1112Z"
				fill={color}
			/>
		</svg>
	);
};

export default Star;
