import React from 'react';

const Background: React.FC = () => {
	return <div className="main-background">
		<div className="main-background">
			<div className="is-relative">
				{Array.from({ length: 19 }).map((_, item) =>
					<div key={item} className={`ellipsis-${item + 1} circle`} />)}
				<div className="main-background__backdrop" />
			</div>
		</div>
	</div>;
};

export default Background;
