import React from 'react';

import { useNotFoundPageProviderContext } from '@common/react/components/Core/NotFoundPageProvider/NotFoundPageProvider';

interface Props {
	defaultNotFoundComponent?: JSX.Element;
}

const NotFoundComponent: React.FC<Props> = ({ defaultNotFoundComponent }) => {
	const notFoundComponentContext = useNotFoundPageProviderContext();

	return notFoundComponentContext?.state?.notFoundPage || defaultNotFoundComponent || '';
};

export default NotFoundComponent;
