import React from 'react';

import Popover, { PopoverProps } from 'antd/lib/popover';
import Checkbox, { CheckboxOptionType } from 'antd/lib/checkbox';

interface IProps {
	onChange: (values) => void;
	options: Array<CheckboxOptionType>;
	values?: Array<string>;
	defaultValue?: Array<string>;
	buttonIcon?: React.ReactNode;
	buttonClassName?: string;
	dropDownClassName?: string;
	buttonStyles?: React.CSSProperties;
	popoverProps?: PopoverProps;
}

const getPopupContainer = (node) => node.closest('.checkbox-list') || document.body;

const CheckboxList: React.FC<IProps> = ({
	options, onChange, values: propsValues, ...p
}) => {
	const {
		defaultValue = [],
		buttonIcon = <i className="fa fa-caret-down" />,
		buttonClassName = 'btn btn-default btn-sm',
		buttonStyles = {},
		dropDownClassName = '',
		popoverProps,
	} = p;
	const [values, setValues] = React.useState<Array<string>>(defaultValue);

	const handleChange = (v) => {
		setValues(v);
		onChange(v);
	};

	React.useEffect(() => {
		if (propsValues) {
			setValues(propsValues);
		}
	}, [propsValues]);

	return <div className="checkbox-list">
		<Popover
			title=""
			trigger="click"
			{...popoverProps}
			overlayClassName={`checkbox-list-wrapper ${dropDownClassName}`}
			content={
				<Checkbox.Group onChange={handleChange} value={values}>
					{options.map((option, i) => <React.Fragment key={i}>
						<Checkbox {...option}>{option.label}</Checkbox>
					</React.Fragment>)}
				</Checkbox.Group>
			}
			getPopupContainer={getPopupContainer}
		>
			<button className={buttonClassName} style={buttonStyles} type="button">
				{buttonIcon}
			</button>
		</Popover>
	</div>;
};

export default CheckboxList;
